import { Expo, gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

(function () {
  const tl = gsap.timeline({ paused: true });
  const timelineClouds = gsap.timeline({ repeat: true });
  const timelineVerticalParallax = gsap.timeline({ paused: true });
  const timelineCollapseRotate = gsap.timeline({ paused: true });
  const timelineCollapseSlide = gsap.timeline({ paused: true });
  const $timeline = document.querySelector('.timeline');
  const $clouds = document.querySelector('.clouds');
  const $city = document.querySelector('.city');
  const $trees = document.querySelector('.trees');
  const $intro = document.querySelector('.intro');
  const $icons = document.querySelector('.journey');

  let slideCollapseButtonAtPercent = 0;

  let collapseButtonLeft = true;
  let showingIntro = false;
  let scrollY = window.scrollY;
  let scrollX = $timeline.scrollLeft;
  let scrollTotal = null;
  let scrollXPercent = 0;
  let scrollYPercent = 0;

  const $collapse = document.querySelector('.collapse-button');

  $collapse.addEventListener('click', function (e) {
    e.preventDefault();

    // pointing left = hide or scroll...
    if (collapseButtonLeft) {
      if (showingIntro) {
        closeIntro();
      } else {
        gsap.to($timeline, { duration: 0.4, scrollLeft: 350, ease: Expo.out });
      }
    } else {
      if (!showingIntro) {
        showingIntro = true;
        $intro.classList.add('show');
        buttonRotateToLeft();
      }
      // arrow pointing right...
    }
  });

  init();

  function closeIntro() {
    showingIntro = false;
    $intro.classList.add('transition');
    $intro.classList.remove('show');
    setTimeout(() => $intro.classList.remove('transition'), 400);
    buttonRotateToRight();
  }

  function init() {
    setDimensions();
    buildRotateTimeline();
    buildCollapseSlideTransition();
    buildXTimeline();
    buildYTimeline();
    horizontalParallax();
    verticalParallax();
  }

  function setDimensions() {
    scrollTotal = $timeline.scrollWidth - $timeline.getBoundingClientRect().width;
  }

  function buildRotateTimeline() {
    timelineCollapseRotate.fromTo(
      $collapse.querySelectorAll('.back-arrow'),
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.3,
        onUpdate() {
          const timelinePercent = this._tTime / this._dur;
          if (collapseButtonLeft && timelinePercent >= 0.5) {
            collapseButtonLeft = false;
          } else if (!collapseButtonLeft && timelinePercent < 0.5) {
            collapseButtonLeft = true;
          }
        },
      }
    );
  }

  function buildCollapseSlideTransition() {
    const moveX = $collapse.getBoundingClientRect().width;
    timelineCollapseSlide.fromTo(
      $collapse,
      { x: 0 },
      {
        x: `${moveX}px`,
        ease: 'none',
      }
    );
  }

  function buildXTimeline() {
    timelineClouds.fromTo(
      $clouds,
      { backgroundPosition: '0 0' },
      { backgroundPosition: `${-scrollTotal}px 0`, duration: 200 }
    );

    gsap
      .timeline({ repeat: -1, yoyo: true })
      .fromTo($clouds, { y: '0' }, { y: '-5px', duration: 4 });

    const bgPos = scrollTotal * -0.08;
    const cityPos = scrollTotal * -0.1;
    const treesPos = scrollTotal * 0.1;
    const iconsPos = scrollTotal * -0.09;

    const introRect = $intro.getBoundingClientRect();
    const introPos = -(introRect.width + introRect.left);

    slideCollapseButtonAtPercent = 1 - $collapse.getBoundingClientRect().width / introRect.width;

    const duration = 1;

    tl.add('start')
      .fromTo(
        $intro,
        { x: 0 },
        {
          x: `${introPos}px`,
          ease: 'none',
          duration: duration * 0.1,
          onUpdate: updateIntroAnimation,
        },
        'start'
      )
      .fromTo(
        $icons,
        { x: 0 },
        {
          x: `${iconsPos}px`,
          ease: 'none',
          duration,
        },
        'start'
      )
      .fromTo(
        $trees,
        { backgroundPosition: '0px 100%' },
        { backgroundPosition: `${treesPos}px 100%`, ease: 'none', duration },
        'start'
      )
      .fromTo(
        $city,
        { backgroundPosition: '0px 100%' },
        { backgroundPosition: `${cityPos}px 100%`, ease: 'none', duration },
        'start'
      );
  }

  function buildYTimeline() {
    timelineVerticalParallax
      .add('start')
      .fromTo($city, { y: '0' }, { y: `120px`, ease: 'none' }, 'start')
      .fromTo($clouds, { y: '0' }, { y: `150px`, ease: 'none' }, 'start');
  }

  function horizontalParallax() {
    // Avoid calculations if not needed
    if (scrollX === $timeline.scrollLeft) {
      return window.requestAnimationFrame(horizontalParallax);
    }

    scrollX = $timeline.scrollLeft;
    scrollXPercent = scrollX / scrollTotal;

    animateXParallax(scrollXPercent);
    window.requestAnimationFrame(horizontalParallax);
  }

  function verticalParallax() {
    // Avoid calculations if not needed
    if (scrollY === window.scrollY) {
      return window.requestAnimationFrame(verticalParallax);
    }

    scrollY = window.scrollY;
    scrollYPercent = scrollY / 400;

    animateYParallax(scrollYPercent);
    window.requestAnimationFrame(verticalParallax);
  }

  function animateXParallax(percent = 0) {
    tl.progress(percent);
  }

  function animateYParallax(percent = 0) {
    timelineVerticalParallax.progress(percent);
  }

  function buttonRotateToRight() {
    timelineCollapseRotate.progress(100);
  }

  function buttonRotateToLeft() {
    timelineCollapseRotate.progress(0);
  }

  let timelinePercent = 0;
  let collapsePercent = 0;

  function updateIntroAnimation() {
    timelinePercent = this._tTime / this._dur;
    if (isNaN(timelinePercent)) {
      return;
    }

    collapsePercent = Math.max(
      0,
      (timelinePercent - slideCollapseButtonAtPercent) / (1 - slideCollapseButtonAtPercent)
    );

    timelineCollapseSlide.progress(collapsePercent);

    if (!showingIntro) {
      timelineCollapseRotate.progress(collapsePercent);
    }
  }

  const $links = document.querySelectorAll('.js-start-exploring');

  $links.forEach(setUpButton);

  function setUpButton(link) {
    link.addEventListener('click', scrollTo);
  }

  function scrollTo(e) {
    e.preventDefault();

    const href = this.href;
    const [url, id] = href.split('#');

    const $el = document.getElementById(id);

    if (!$el) {
      return;
    }

    const rect = $el.getBoundingClientRect();
    const xCoord = rect.left;
    const postition = $timeline.scrollLeft + xCoord - window.innerWidth / 2;

    gsap.to($timeline, {
      duration: 0.4,
      scrollLeft: postition,
      ease: Expo.out,
      onComplete: function () {
        $el.setAttribute('tabindex', -1);
        $el.focus();
        $el.classList.add('open');
      },
    });

    closeIntro();
  }

  const scrollDown = document.querySelector('.js-about');
  const $about = document.getElementById('about');
  scrollDown.addEventListener('click', scrollToAbout);

  function scrollToAbout(e) {
    e.preventDefault();

    gsap.to(window, {
      duration: 0.4,
      scrollTo: '#about',
      ease: Expo.out,
    });
  }
})();
