require('./js/accordion');
require('./js/icons');
require('./js/timelines');
require('./js/strip-animation');
require('./js/drag-scroll');

setSize();
window.addEventListener('resize', setSize);

function setSize() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
