(function () {
  const $strip = document.querySelector('.strip');
  const arrowWidth = 30;

  populateStrip();

  function populateStrip() {
    const stripWidth = $strip.getBoundingClientRect().width;
    const totalItems = Math.floor(stripWidth / arrowWidth);
    const items = [];

    for (let i = 0; i <= totalItems; i++) {
      items.push('<div></div>');
    }

    $strip.innerHTML = items.join('');
  }
})();
