(function () {
  const $buttons = document.querySelectorAll('.icon button');
  const $wrap = document.querySelector('.timeline-wrap');

  $buttons.forEach(setUpButton);
  $wrap.addEventListener('click', closeAll);

  function setUpButton(button) {
    button.addEventListener('click', toggleIcon);
  }

  function toggleIcon(e) {
    e.preventDefault();

    const $parent = this.closest('.icon');
    const isOpen = $parent.classList.contains('open');

    if (!isOpen) {
      // after we close the others, open this one up
      setTimeout(() => $parent.classList.add('open'), 0);
    }
  }

  function closeAll(e) {
    if (e.target.classList.contains('js-iframe-toggler')) {
      return;
    }

    document.querySelectorAll('.icon.open').forEach((button) => {
      button.classList.remove('open');
    });
  }

  document
    .querySelectorAll('.js-iframe-toggler')
    .forEach((toggler) => toggler.addEventListener('click', toggleIframe));

  const $iframeContainer = document.querySelector('.js-frame-container');
  const $iframe = $iframeContainer.querySelector('iframe');

  function toggleIframe(e) {
    e.preventDefault();

    const isOpen = $iframeContainer.classList.contains('open');

    if (this.href) {
      const $current = document.querySelector('.active-iframe');

      if ($current) {
        $current.classList.remove('active-iframe');
      }

      this.classList.add('active-iframe');
      $iframe.src = this.href;
      $iframe.title = this.title;
      $iframeContainer.classList.add('open');
      $iframe.focus();
    } else {
      // close it
      const $current = document.querySelector('.active-iframe');

      if ($current) {
        $current.focus();
        $current.classList.remove('active-iframe');
      }

      $iframeContainer.classList.remove('open');
      // it's going to close, so lets remove the src
      setTimeout(() => {
        $iframe.src = '';
        $iframe.title = '';
      }, 400);
    }
  }
})();
