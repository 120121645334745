import { Expo, gsap } from 'gsap';

(function () {
  const $tabs = document.querySelectorAll('.button-toggle');
  const openClass = 'button-toggle--open';

  $tabs.forEach(setUpButton);

  function setUpButton(button) {
    button.addEventListener('click', toggleAccordion);
  }

  function enableTab(tab) {
    tab.classList.add(openClass);
    scrollToTab(tab);
  }

  function scrollToTab(tab) {
    const $parent = tab.parentElement;
    const top =
      $parent.scrollTop - $parent.getBoundingClientRect().top + tab.getBoundingClientRect().top;
    gsap.to($parent, { duration: 0.4, scrollTop: top, ease: Expo.out });
  }

  function disableTab(tab) {
    tab.classList.remove(openClass);
  }

  function toggleAccordion(e) {
    e.preventDefault;
    const isOpen = this.classList.contains(openClass);
    $tabs.forEach(disableTab);

    if (!isOpen) {
      enableTab(this);
    }
  }
})();
